
/**
 * Main Navigation template.
 * 
 * @package 
*/

function isInViewport() {
    let anchor = document.querySelectorAll( '.mod_article[id]' );
    const documentHash = document.location.hash.substring( 1 );
    if ( anchor.length > 0) {
        anchor.forEach( function( element, i ) {
            const elmentBounding = element.getBoundingClientRect();
            
            let elementTop = Math.round(elmentBounding.top);
            let elementBottom = Math.round(elmentBounding.bottom);
            
            const elementID = element.id;
            if ( 100 >= elementTop && elementBottom >= 50 ) {
                if ( documentHash != elementID ) {
                    history.pushState( { }, '', '#' + elementID ); 
                }
            }
            
        } );
    } 
}



// Scroll Function 
function createScrollStopListener( element, callback, timeout ) {
var handle = null;
function onScroll() {
    if ( handle ) {
        clearTimeout( handle );
    }
    handle = setTimeout( callback, timeout || 0 ); // default 200 ms
}
element.addEventListener( 'scroll', onScroll );
return function() {
    element.removeEventListener( 'scroll', onScroll );
};
}

// Change Classes after Scrolling Events
createScrollStopListener( document, function() {
const documentHash = document.location.hash.substring(1);
const activeMenuItems = document.querySelectorAll('.mod_onepage_navigation .onepage_navigation__list > li a');
isInViewport();
activeMenuItems.forEach( function( element ) {
    if ( element.hash.substring(1) == documentHash) {
        element.classList.add('active');
        const navButton = document.getElementById( 'navButton' );
        const navButtonDataSet = navButton.dataset.nav;
        const body = document.querySelector( 'body' );
        if ( navButtonDataSet == 'open' ) {
            body.classList.remove( 'navIsOpen' );
            body.classList.add( 'navIsClosed' );
            navButton.setAttribute( 'data-nav','closed' ); 
        } 
       
    } else {
        element.classList.remove('active');
    }
})    
}, 0);

// Helper 
function addListenerMulti( element, eventNames, listener ) {
var events = eventNames.split(' ');
for (var i=0, iLen=events.length; i<iLen; i++) {
    element.addEventListener( events[i], listener, false );
}
}


function checkActiveMenuItem(findHash,documentHash) {
return findHash = documentHash.hash;
}




window.addEventListener( 'load', ( event ) => {
    const navButton = document.getElementById( 'navButton' );
    console.log( 'navButton start' );
    console.log( navButton );
    console.log( 'navButton end' );
    
    
    const body = document.querySelector( 'body' );
    // const menuElement = document.querySelector( '.main-menu' );
    toggleNav( navButton, body );
    // // closeNav( navButton, menuElement );

    document.getElementById("klaroshow") && (document.getElementById("klaroshow").onclick = function () { klaro.show() })

});

function toggleNav( navButton, body ) {

    
    navButton.addEventListener( 'click', ( event ) => {
        const navButtonDataSet = navButton.dataset.nav;
        if ( navButtonDataSet == 'close' ) {
            body.classList.add( 'navIsOpen' );
            navButton.setAttribute( 'data-nav','open' );
        } else {
            navButton.setAttribute( 'data-nav','close' );
            body.classList.remove( 'navIsOpen' );
            
        }
    });
}